p {
  margin-top: 9px;
}
.miniDashboardContainer {
  word-wrap: break-word;
  white-space: pre-line;
  font-weight: 400;
  font-size: 12px;
  /* text-align: center; */
  display: inline-block;
  vertical-align: top;
  position: relative;
  /* width: 80px; */
  margin-left: 30px;
}
.miniDashboardInfo {
  word-wrap: break-word;
  white-space: pre-line;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  /* text-align: center; */
  /* height: 64px; */
  /* width: 68px; */
  /* position: relative; */
  /* margin: auto;
  overflow: hidden; */
}

.miniDashboardContent {
  word-wrap: break-word;
  white-space: pre-line;
  font-weight: 400;
  font-size: 14px;
  /* text-align: center; */
  /* position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; */
  /* margin: auto;
  height: 30px; */
}

.miniDashboardNumber {
  word-wrap: break-word;
  white-space: pre-line;
  /* text-align: center; */
  /* margin: 0 0 0.25rem; */
  font-size: 12px;
  /* font-weight: 700; */
  /* margin: 0; */
}

.miniDashboardDaysInfo {
  word-wrap: break-word;
  white-space: pre-line;
  font-weight: 400;
  /* text-align: center; */
  /* margin: 0 0 0.25rem; */
  /* margin-top: -10px; */
  font-size: 12px;
  user-select: none;
  -webkit-user-drag: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}

.miniDashboardInfoDisable {
  word-wrap: break-word;
  white-space: pre-line;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4px;
  /* text-align: center; */
  /* height: 64px; */
  width: 68px;
  /* position: relative; */
  /* margin: auto; */
  /* overflow: hidden; */
}

.labelDisable {
  color: #bdbdbd;
  font-size: 12px;
}
