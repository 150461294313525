.Filter {font-family: Roboto,Helvetica Neue,sans-serif;
font-weight: 700;
display: table-cell;
position: relative;
padding: 22px 1em;
text-align: center;
vertical-align: middle;
color: hsla(0,0%,100%,.5);
text-transform: capitalize;
width: 33%;
background: #4fc3f7;
border-color: #4fc3f7;
padding-left: 2em;
}

.filterPipline {
    font-family: Roboto,Helvetica Neue,sans-serif;
    color: rgba(0,0,0,.87);
    display: flex;
    align-items: center;
}