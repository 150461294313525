.listBody {
  width: 95%;
  margin: auto;
  margin-top: 30px;
  max-width: 83vh;
  height: 85vh;
}

.listItems {
  max-height: 55vh;
  overflow: auto;
}

.addBtn {
  display: flex;
}
