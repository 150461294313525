@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap");

:root {
  --scrollbar-thumb: #8B8B8B;
  --scrollbar-track: #E8E8E8;
  --scrollbar-thumb-hover: #5D5D5D;
}

[data-theme="dark"] {
  --scrollbar-thumb:  #5D5D5D;
  --scrollbar-track: #131313;
  --scrollbar-thumb-hover: #8B8B8B;
}

body {
  margin: 0;
  font-family: "Manrope" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* this class is used for auto-complete dropdown of google places(to display the dropdown above other elements) */
.pac-container {
  z-index: 1301 !important;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
::-webkit-scrollbar-track-piece {
  background: var(--scrollbar-track);
}
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 6px;
  border: 3px solid var(--scrollbar-track);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}
/* End Srollbar Styles */
