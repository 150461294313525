.darkToolbar{
  filter: invert(0.9);
}

.darkToolbar .rdw-emoji-modal,
.darkToolbar .rdw-colorpicker-modal,
.darkToolbar .rdw-link-modal,
.darkToolbar .rdw-embedded-modal,
.darkToolbar .rdw-image-modal {
  top: -125px;
  left: -65px;
  color: #000;
}

.darkToolbar .rdw-emoji-icon,
.darkToolbar .rdw-colorpicker-cube {
  filter: invert(1);
}

.darkToolbar .rdw-dropdown-optionwrapper {
max-height: 100px;
top:-50px
}

.rdw-dropdown-wrapper{
  color:#000 !important;
}
.rdw-block-wrapper.darkToolbar{
  color:"#000"
}

.darkToolbar ::-webkit-scrollbar-track-piece {
  background: #E8E8E8;
}
.darkToolbar ::-webkit-scrollbar-thumb {
  background: #8B8B8B;
  border: 3px solid #E8E8E8;
}
.darkToolbar ::-webkit-scrollbar-thumb:hover {
  background: #5D5D5D;
}
