@import url('https://fonts.googleapis.com/css2?family=Asap&display=swap');

:root{
  --fc-border-color: #94A3B888;
}

.fc-daygrid-day-top {
  flex-direction: row !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

.fc-day-other.lightMode {
  background-color: #F8FAFC;
}

.fc-day-other.darkMode {
  background-color: #272727;
}

.fc-daygrid-day-frame {
  height: 165px !important;
  overflow-y: auto;
  overflow-x: hidden;
}
/* .fc-daygrid-day-frame::-webkit-scrollbar {
  display: none;
} */

.fc-col-header-cell{
  border-radius: 10px;
}
